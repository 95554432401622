import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DigitalCert from "../components/DigitalCert";
import CertInfosModal from "../components/CertInfosModal";
import PrintDoc from "../components/PrintDoc";
import { IState } from "../lib/store";
import { getCertificate } from "../lib/helper";
import SelectDiplomaVersion from "../components/SelectDiplomaVersion";

const tempAdminIDs: string[] = [
    '315229f7-51e0-485b-8074-9a623ad29d7f', // M D
    'c9fb567c-9e9c-4a91-8c35-84963abbaff1',
    '80f934b0-128d-446d-9973-6e1f01d8b8a8',
    'da9f09d9-ca97-4a30-b47b-77817d687726', // M S
    '91e18802-a072-47c9-85be-b29c7a356628' // A T
];

const get = (year: string, grade: string, program: string, hash: string) => (state: IState) => {
    return getCertificate(hash, state.projects.certificates[year!][grade!][program!])
}

export const CerficatePreview: React.FC = () => {
    const { year='', grade='', program='', hash='' } = useParams();
    const certificate = useSelector(get(year, grade, program, hash), shallowEqual);
    const [openMetadata, setOpenMetadata] = useState<boolean>(false);
    const user = useSelector((state: IState) => state.auth.profile, shallowEqual)!;
    const [templateVersion, setTemplateVersion] = useState('v1');
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!certificate) {
            navigate('/');
        }
    }, [certificate]);

    const getStatus = () => {
        switch(certificate?.certified) {
            case true:
            return <span style={{color: 'green'}}>Active</span>
            case false:
            return <span style={{color: 'orange'}}>En cours de certification</span>
        }
    }


    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            height="100%"
        >
            <Box width="100%" display="flex" justifyContent="space-between" className="cert-prev">
                {
                    tempAdminIDs.includes(user.id!) && (
                        <div style={{flex: 1}}>
                            <Button style={{flex: 1}} color="info" variant="outlined" onClick={() => setOpenMetadata(true)} >Detail</Button>
                        </div>
                    )
                }
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{paddingRight: '1rem', marginRight: '1rem', borderRight: certificate?.certified ? 'solid 2px' : undefined, color: '#607489'}}>
                        <strong>Statut:</strong><span className="cert-status">{getStatus()}</span>
                    </span>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                    {/* {certificate?.certified && (
                        <PrintDoc certificate={certificate!} />
                    )} */}
                    <SelectDiplomaVersion version={templateVersion} grade={certificate!.grade} onSelect={(version) => setTemplateVersion(version)} />
                    <PrintDoc templateVersion={templateVersion} certificate={certificate!} />
                </div>
            </Box>


            <Box height="100%" width="100%">
                {certificate && <DigitalCert certificate={certificate!} />}
            </Box>


            <CertInfosModal open={openMetadata} close={() => setOpenMetadata(false)} certificate={certificate!} />

        </Box>

        
    )
}