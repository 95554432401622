import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ICertificate } from "../../entities/certificates";
import { ITemplate } from "../../entities/organization";
import { useTemplateFonts } from "../../lib/contexts/TemplateFonts";
import { getSigle, removeAccents } from "../../lib/helper";
import { AppDispatch, IState } from "../../lib/store";
import { certPrinted } from "../../lib/store/projects/thunks";
import ConfirmationDialog from "../ConfirmationDialog";
import { downloadAsZip } from "../DownloadCertificates/AsZip";
import { enhanceMetadata, generatePDF, getTemplate } from "../PrintDoc/helper";


interface IProps {
    certHashes: string[];
    templateVersion: string;
}

const getCerts = (ids: string[]) => (certificates: ICertificate[]) => {
    return certificates.filter((c) => ids.includes(c.hash!) && !!c.ine);
}

const ConfirmBody: React.FC<{metadatas: Record<string, string>[]}> = ({metadatas}) => {
    return (
        <Box>
            <Typography>Diplômes imprimés avec succès ? veuillez confirmer si tel est le cas.</Typography>
            <Typography><strong>Numéro de series:</strong></Typography>
            <ul>
                {metadatas.map((m, i) => (
                    <li key={i}>{m['serial_number']}</li>
                ))}
            </ul>
        </Box>
    )
}

const Component: React.FC<IProps> = ({certHashes, templateVersion}) => {
    const { year, grade, program } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const templates = useSelector((state: IState) => state.organization.orgs[0].templates, shallowEqual)!;
    const [blankTemplate, setBlankTemplate] = useState<ITemplate>();
    const [templateNotFound, setTemplateNotFound] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [metadatas, setMetadatas] = useState<Record<string, string>[]>([]);
    const [openAckDialog, setOpenAckDialog] = useState<boolean>(false);
    const universityName = useSelector((state: IState) => state?.organization?.organization?.name);
    const certificates = useSelector((state: IState) => getCerts(certHashes)(state.projects.certificates[year!][grade!][program!]));
    const templateFonts = useTemplateFonts();

    useEffect(() => {
        if (certHashes.length > 0 && certificates.length === 0) {
            toast.warn('ine manquant dans la selection');
            return;
        }
        const dTpl = getTemplate(removeAccents(certificates[0]!.metadata!['grade']))(templates, templateVersion) || getTemplate('Licence')(templates, templateVersion);
        const blankTpl = getTemplate('Blank')(templates, templateVersion);
        if (!dTpl || !dTpl.print) {
            setTemplateNotFound(true);
            return;
        }

        const bTpl = {
            ...dTpl?.print,
            template: {
                ...dTpl?.print.template,
                basePdf: blankTpl?.preview.template.basePdf!,
            },
        }

        setBlankTemplate(JSON.parse(JSON.stringify(bTpl)));
        setMetadatas(certificates.map((c) => enhanceMetadata({...c!.metadata!}, universityName)));
    }, [certHashes, templateVersion]);

    const print =  async () => {
        if (templateNotFound) {
            toast.error(`template d\'impression version: ${templateVersion} de diplôme introuvable, veuillez contacter le support.`);
            return;
        }

        const unsets: string[] = [];
        blankTemplate?.template.columns?.map((c: string) => {
            if (!(metadatas[0] && metadatas[0][c])) {
                unsets.push(c);
            }
        });

        if (unsets.length > 0) {
            toast.error(
                <>
                    <span>Ces champs suivant du diplome sont manquants ou mal formattés:</span>
                    <ul>
                        {unsets.map((c) => (
                            <li>{c}</li>
                        ))}
                    </ul>
                </>
            );
            return;
        }
            
        setLoading(true);

        const files: {location: string, filename: string}[] = await Promise.all(metadatas
            .map((m) => generatePDF({...blankTemplate?.template!}, [m], templateFonts.fonts)))
            .then((files: File[]) => files.map((f) => ({
                location: URL.createObjectURL(f),
                filename: f.name
            })));
        
        downloadAsZip(files, `${getSigle(universityName)}_${metadatas[0]['grade']}_${metadatas[0]['annee_obtention']}`)
            .then(() => setOpenAckDialog(true))
            .catch(() => toast.error('Une erreur est survenue pendant l\'impression, veuillez contacter le support si le problème persiste...'))
            .finally(() => setLoading(false))
    }

    const onPrinted = () => {
        setLoading(true);
        dispatch(certPrinted(metadatas.map(m => ({hash: m['hash'], serialNumber: m['serial_number']})))).unwrap()
            .then(() => toast.success('Impression terminée'))
            .catch(() => toast.error('Une erreur est survenue, veuillez contacter le support si le problème persiste...'))
            .finally(() => {
                setLoading(false);
                setOpenAckDialog(false);
            });
    }

    return (
        <Box>
            {
                certificates.length > 0 && (
                    <LoadingButton loading={loading} variant="outlined" color="inherit" onClick={print}>
                        <DownloadIcon />
                    </LoadingButton>
                )

            }
        
            <ConfirmationDialog
                open={openAckDialog}
                close={() => setOpenAckDialog(false)}
                actionLabel='Retour impression'
                contentText={<ConfirmBody metadatas={metadatas} />}
                succesLabel='Oui'
                errorLabel='Non'
                loading={loading}
                action={onPrinted}
                />
        </Box>
    )
}

export default Component;