import { useSelector, shallowEqual } from "react-redux";
import { IState } from "../../lib/store";
import { ITemplate } from "../../entities/organization";
import { removeAccents } from "../../lib/helper";
import { Box, Button } from "@mui/material";
import { useState } from "react";

interface IProps {
    version: string;
    grade: string;
    onSelect: (version: string) => void;
}

const getTemplateVersions = (tpls: ITemplate[], grade: string): string[] => tpls.filter(({name}) => name.includes(removeAccents(grade))).map(({name}) => name.toLocaleLowerCase());

const Component: React.FC<IProps> = ({version, grade, onSelect}) => {
    const templateNames = useSelector((state: IState) => getTemplateVersions(state.organization.orgs[0].templates!, grade), shallowEqual)!;
    const [selected, setSelected] = useState<string>(version);
    const isV2 = templateNames.some(name => name.includes('v2'));

    const _onSelect = (v: string) => {
        setSelected(v);
        onSelect(v);
    }

    return (
        <Box display="flex" gap={1}>
            <Button
                disabled={!isV2}
                variant="outlined"
                color="inherit"
                sx={{color: selected === 'v1' ? 'white !important' : '', bgcolor: selected === 'v1' ? 'rgb(68, 89, 110)': ''}}
                onClick={() => _onSelect('v1')}
            >V1</Button>
            {isV2 && (
                <Button
                    variant="outlined"
                    color="inherit"
                    sx={{color: selected === 'v2' ? '#ffff' : '', bgcolor: selected === 'v2' ? 'rgb(68, 89, 110)': ''}}
                    onClick={() => _onSelect(selected === 'v1' ? 'v2' : 'v1')}
                >V2</Button>)}
        </Box>
    )
}

export default Component;